.nav-logo {
    width: 280px;
    height: 60px;
    margin-top: 20px;
}

.nav-home-logo {
    width: 30px;
    height: 30px;
}

.bg-body-tertiary {
    background-color: white;
}

.container-fluid {
    padding-bottom: 20px;
}


.navbar-brand {
    font-weight: bold;
    font-size: 30px;
    font-family: 'Times New Roman', Times, serif;
}

.nav-link {
    margin-left: 10px;
    color: black;
}

.nav-link:hover {
    color: rgb(255, 0, 0);
}

.navbar-all-links {
    text-align: center;
    background-color: rgb(246, 249, 250);
}

.logo {
    padding-left: 20px;
}

.navbar-nav {
    padding-left: 30px;
}

.vi-but {
    width: 130px;
    border-radius: 25px;
    box-shadow: 0 16px 32px 0 rgba(0,0,0,0.2);
    margin-left: 100px;
}

.vi-but:hover {
    color: white;
    background-color: black;
}

.logo {
    text-decoration: none;
    font-family: 'Times New Roman', Times, serif;
    font-weight: bold;
    font-size: 25px;
}

.nav-link {
    font-size: 14px;
    font-weight: bold;
}

@media (max-width: 768px) {
    .navbar-nav {
        padding-left: 0px;
        padding-top: 20px;
    }

    .vi-but {
        width: 130px;
        border-radius: 25px;
        box-shadow: 0 16px 32px 0 rgba(0,0,0,0.2);
        margin-left: 10px;
        margin-top: 15px;
        margin-bottom: 20px;
    }

    .logo {
        padding-left: 10px;
    }
}

/* footer */

.foo {
    margin-left: 540px;
    margin-top: 30px;
}

.container-footer {
    background-color: black;
    color: white;
    padding-left: 50px;
}

.footer-h {
    font-weight: bold;
}

.line {
    color: gray;
    font-weight: bold;
}

.footer-social-icon {
    width: 20px;
    height: 20px;
}

.footer-social-icon-you {
    width: 50px;
    height: 40px;
    padding-bottom: 10px;
}

.footer-main-title {
    text-align: center;
    padding-top: 40px;
    padding-bottom: 30px;
    font-family: 'Times New Roman', Times, serif;
}

.copy {
    padding-left: 300px;
}

.footer-icons-ic {
    padding-left: 50px;
}

.footer-links-gray {
    color: rgb(161, 160, 160);
    text-decoration: none;
}

.footer-h-s {
    color: black;
}

@media (max-width: 768px) {
    .foo {
        margin-left: 10px;
    }
}

/* cont-one-home */

.cont-main-one {
    padding-left: 100px;
    padding-top: 30px;
}

.wel-text {
    color: rgb(100, 100, 100);
}

.main-one-img {
    width: 300px;
    height: 300px;
}

.inside-cont {
    background-color: black;
    color: white;
}

.btn-primary {
    background-color: black;
}

.side-car {
    margin-left: 20px;
}

.side-s {
    box-shadow: 0 16px 32px 0 rgba(0,0,0,0.2);
}

.main-one-title-para {
    color: rgb(161, 160, 160);
}

.main-one-title {
    padding-top: 15px;
    font-family: 'Times New Roman', Times, serif;
}

.home-cont-tit-one {
    font-family: 'Times New Roman', Times, serif;
}

@media (max-width: 768px) {
    .cont-main-one {
        padding-left: 20px;
        padding-top: 30px;
    }

    .main-one-img {
        width: 395px;
        height: 300px;
        padding-bottom: 15px;
    }

    .side-car {
        margin-left: 0px;
        margin-top: 20px;
    }
}

.img-fours {
    width: 304px;
    height: 200px;
}

.img-fours-two {
    width: 304px;
    height: 200px;
}

.img-fours-three {
    width: 304px;
    height: 200px;
}

.img-fours-four {
    width: 304px;
    height: 300px;
    max-height: 300px;
}

.img-fours-three-on {
    height: 170px;
}

.img-fours-three-lea {
    height: 200px;
}

.img-fours-three-le {
    height: 200px;
}

.todays-news {
    text-align: center;
    font-family: 'Times New Roman', Times, serif;
    font-size: 30px;
}

.to-do-img {
    width: 220px;
    height: 80px;
    margin-left: 535px;
    margin-bottom: 45px;
}

.card-one {
    box-shadow: 0 16px 32px 0 rgba(0,0,0,0.2);
    transition: 0.3s;
  }
  
  .card-one:hover {
    box-shadow: 0 16px 32px 0 rgba(0,0,0,0.2);
  }

.view-all {
    width: 130px;
    padding-left: 0px;
    border-radius: 25px;
    margin-left: 585px;
    margin-top: 40px;
    box-shadow: 0 16px 32px 0 rgba(0,0,0,0.2);
}

.view-all:hover {
    color: white;
    background-color: black;
}

.cont-three {
    background-color: black;
    margin-left: 150px;
    margin-right: 150px;
    text-align: center;
}

.cont-thr-img {
    width: 580px;
    height: 600px;
}

.cont-three-r {
    padding-left: 70px;
    padding-right: 70px;
}

.history {
    text-decoration: none;
    color: rgb(255, 2, 2);
    font-family: 'Times New Roman', Times, serif;
    padding-top: 100px;
}

.history-long {
    text-decoration: none;
    color: gray;
    font-family: 'Times New Roman', Times, serif;
    font-size: 40px;
}

.history-had {
    text-decoration: none;
    color: white;
    font-family: 'Times New Roman', Times, serif;
}

.history-name {
    text-decoration: none;
    color: rgb(255, 2, 2);
    font-family: 'Times New Roman', Times, serif;
}

.dot {
    color: black;
    margin-bottom: 60px;
}

.sports {
    color: red;
    font-family: Georgia, 'Times New Roman', Times, serif;
}

.card-text-around {
    color: rgb(77, 77, 77);
}

.name-name {
    text-decoration: none;
    font-weight: bold;
    color: black;
}

.card-title-around {
    font-family: 'Times New Roman', Times, serif;
    font-size: 20px;
    color: black;
    font-weight: bold;
}

@media (max-width: 768px) {
    .to-do-img {
        width: 220px;
        height: 80px;
        margin-left: 80px;
        margin-bottom: 30px;
    }

    .card-one {
        margin-bottom: 50px;
    }

    .cont-three {
        background-color: black;
        margin-left: 10px;
        margin-right: 10px;
        text-align: center;
    }
    
    .cont-thr-img {
        width: 100%;
        height: 400px;
    }
    
    .cont-three-r {
        padding-left: 70px;
        padding-right: 70px;
    }
    
    .history {
        text-decoration: none;
        color: rgb(255, 2, 2);
        font-family: 'Times New Roman', Times, serif;
    }
    
    .history-long {
        text-decoration: none;
        color: gray;
        font-family: 'Times New Roman', Times, serif;
        font-size: 25px;
    }
    
    .history-had {
        text-decoration: none;
        color: white;
        font-family: 'Times New Roman', Times, serif;
    }
    
    .history-name {
        text-decoration: none;
        color: rgb(255, 2, 2);
        font-family: 'Times New Roman', Times, serif;
    }
    
    .dot {
        color: black;
        margin-bottom: 10px;
    }   
    
    .view-all {
        width: 130px;
        padding-left: 0px;
        border-radius: 25px;
        margin-left: 130px;
        margin-top: 0px;
        box-shadow: 0 16px 32px 0 rgba(0,0,0,0.2);
    }
}

/* education */

.blog-one-edu {
    width: 230px;
    height: 170px;
    box-shadow: 0 8px 16px 0 rgba(0,0,0,0.2);
}

a {
    text-decoration: none;
}

.edu-title-one {
    font-family: 'Times New Roman', Times, serif;
    font-size: 22px;
    color: black;
}

.edu-para-one {
    font-family: 'Times New Roman', Times, serif;
    color: rgb(85, 84, 84);
}

.edu-name-one {
    font-family: 'Times New Roman', Times, serif;
    font-weight: bold;
    color: black;
    font-size: 14px;
}

.edu-date-one {
    font-family: 'Times New Roman', Times, serif;
    color: black;
    font-size: 14px;
}

.cont-one-education {
    padding-left: 90px;
    padding-top: 50px;
}

.hr-line {
    width: 810px;
}

.edu-link-one {
    font-family: 'Times New Roman', Times, serif;
    font-weight: bold;
    color: rgb(255, 0, 0);
    font-size: 15px;
    padding-left: 340px;
}

@media (max-width: 768px) {
    .hr-line {
        width: 380px;
    }

    .cont-one-education {
        padding-left: 25px;
        padding-top: 50px;
    }

    .blog-one-edu {
        width: 380px;
        height: 250px;
        box-shadow: 0 8px 16px 0 rgba(0,0,0,0.2);
        margin-top: 30px;
    }
}

/* education */

.cont-edu-banner {
    background-image: url(../public/bul.jpg);
    height: 400px;
    background-repeat: no-repeat;
    background-size: cover;
}

.gui-tit {
    color: white;
    text-align: center;
    font-family: 'Times New Roman', Times, serif;
    font-size: 40px;
    padding-top: 50px;
}

@media (max-width: 768px) {
    .cont-edu-banner {
        background-image: url(../public/educationbanner.jpg);
        height: 200px;
        background-repeat: no-repeat;
        background-size: cover;
        background-position: center;
    }
}

.cont-learn-banner {
    background-image: url(../public/learningbanner.jpg) ;
    height: 500px;
    background-repeat: no-repeat;
    background-size: cover;
}

@media (max-width: 768px) {
    .cont-learn-banner {
        background-image: url(../public/learningbanner.jpg);
        height: 200px;
        background-repeat: no-repeat;
        background-size: cover;
        background-position: center;
    }
} 

.cont-humor-banner {
    background-image: url(../public/humorbanner.jpg) ;
    height: 500px;
    background-repeat: no-repeat;
    background-size: cover;
}

@media (max-width: 768px) {
    .cont-humor-banner {
        background-image: url(../public/humorbanner.jpg);
        height: 200px;
        background-repeat: no-repeat;
        background-size: cover;
        background-position: center;
    }
}

.cont-news-banner {
    background-image: url(../public/newsbanner.png) ;
    height: 500px;
    background-repeat: no-repeat;
    background-size: cover;
}

@media (max-width: 768px) {
    .cont-news-banner {
        background-image: url(../public/newsbanner.png);
        height: 200px;
        background-repeat: no-repeat;
        background-size: cover;
        background-position: center;
    }
}

.cont-health-banner {
    background-image: url(../public/healthbanner.png) ;
    height: 500px;
    background-repeat: no-repeat;
    background-size: cover;
}

@media (max-width: 768px) {
    .cont-health-banner {
        background-image: url(../public/healthbanner.png);
        height: 200px;
        background-repeat: no-repeat;
        background-size: cover;
        background-position: center;
    }
}

.cont-reforms-banner {
    background-image: url(../public/reformsbanner.png) ;
    height: 500px;
    background-repeat: no-repeat;
    background-size: cover;
}

@media (max-width: 768px) {
    .cont-reforms-banner {
        background-image: url(../public/reformsbanner.png);
        height: 200px;
        background-repeat: no-repeat;
        background-size: cover;
        background-position: center;
    }
}

.cont-vision-banner {
    background-image: url(../public/visionbanner.png) ;
    height: 500px;
    background-repeat: no-repeat;
    background-size: cover;
}

@media (max-width: 768px) {
    .cont-vision-banner {
        background-image: url(../public/visionbanner.png);
        height: 200px;
        background-repeat: no-repeat;
        background-size: cover;
        background-position: center;
    }
}

.cont-articles-banner {
    background-image: url(../public/articlesbanner.png) ;
    height: 500px;
    background-repeat: no-repeat;
    background-size: cover;
}

@media (max-width: 768px) {
    .cont-articles-banner {
        background-image: url(../public/articlesbanner.png);
        height: 200px;
        background-repeat: no-repeat;
        background-size: cover;
        background-position: center;
    }
}

.cont-polls-banner {
    background-image: url(../public/pollsbanner.png) ;
    height: 500px;
    background-repeat: no-repeat;
    background-size: cover;
}

@media (max-width: 768px) {
    .cont-polls-banner {
        background-image: url(../public/pollsbanner.png);
        height: 200px;
        background-repeat: no-repeat;
        background-size: cover;
        background-position: center;
    }
}

.cont-role-banner {
    background-image: url(../public/world.png) ;
    height: 500px;
    background-repeat: no-repeat;
    background-size: cover;
}

@media (max-width: 768px) {
    .cont-role-banner {
        background-image: url(../public/world.png);
        height: 200px;
        background-repeat: no-repeat;
        background-size: cover;
        background-position: center;
    }
}

.cont-vis-banner {
    background-image: url(../public/educationbanner.jpg);
    height: 500px;
    background-repeat: no-repeat;
    background-size: cover;
}

.vis-tit {
    color: white;
    text-align: center;
    font-family: 'Times New Roman', Times, serif;
    font-size: 50px;
    padding-top: 100px;
}

.vis-para {
    color: white;
    text-align: center;
    font-family: 'Times New Roman', Times, serif;
    font-size: 30px;
}

@media (max-width: 768px) {
    .cont-vis-banner {
        background-image: url(../public/educationbanner.jpg);
        height: 200px;
        background-repeat: no-repeat;
        background-size: cover;
        background-position: center;
    }

    .vis-tit {
        color: white;
        text-align: center;
        font-family: 'Times New Roman', Times, serif;
        font-size: 40px;
        padding-top: 20px;
    }
}

/* box-xpntainer-world */

.box-container {
    background-color: rgb(241, 238, 238);
    width: 1000px;
    margin-left: 210px;
    margin-top: 50px;
}

.box-cont-img {
    width: 250px;
}

.four-cont-col {
    padding-left: 50px;
    padding-top: 30px;
}

.fiction {
    font-family: 'Times New Roman', Times, serif;
}

.paris {
    font-family: 'Times New Roman', Times, serif;
}

.box-font-name {
    padding-left: 60px;
}

.box-font-para {
    padding-left: 47px;
}

.box-font-info {
    font-family: 'Times New Roman', Times, serif;
    padding-top: 70px;
    font-size: 18px;
}

.box-font-info-two {
    font-family: 'Times New Roman', Times, serif;
    font-size: 18px;
}

@media (max-width: 768px) {
    .box-container {
        background-color: rgb(241, 238, 238);
        width: 400px;
        margin-left: 18px;
        margin-top: 50px;
        padding-left: 10px;
        padding-right: 10px;
    }
    
    .box-cont-img {
        width: 250px;
    }
    
    .four-cont-col {
        padding-left: 15px;
        padding-top: 30px;
        text-align: center;
    }
    
    .fiction {
        font-family: 'Times New Roman', Times, serif;
    }
    
    .paris {
        font-family: 'Times New Roman', Times, serif;
    }
    
    .box-font-name {
        padding-left: 60px;
    }
    
    .box-font-para {
        padding-left: 47px;
    }
    
    .box-font-info {
        font-family: 'Times New Roman', Times, serif;
        padding-top: 70px;
        font-size: 18px;
    }
    
    .box-font-info-two {
        font-family: 'Times New Roman', Times, serif;
        font-size: 18px;
    }
}

/*about */

.about-banner {
    background-image: url(../public/abtt.jpg);
    height: 500px;
    background-repeat: no-repeat;
    background-size: cover;
}

.about-name {
    text-align: center;
    font-family: 'Times New Roman', Times, serif;
}

.about-cont-two {
    margin-left: 210px;
    padding-top: 50px;
    padding-bottom: 50px;
    font-family: 'Times New Roman', Times, serif;
}

.who-cont {
    background-color: black;
    color: white;
    padding-top: 30px;
    padding-bottom: 30px;
}

.who-cont-t {
    background-color: whitesmoke;
}

.who-we {
    font-size: 30px;
    padding-bottom: 20px;
    padding-top: 30px;
}

.who-ans {
    color: rgb(223, 221, 221);
}

.teams-name {
    font-family: 'Times New Roman', Times, serif;
    text-align: center;
}

.abt-card-a {
    width: 250px;
    box-shadow: 0 8px 16px 0 rgba(0,0,0,0.2);
}

.team-cont {
    margin-left: 310px;
    margin-top: 30px;
    margin-bottom: 80px;
}

@media (max-width: 768px) {
    .about-banner {
        background-image: url(../public/abtt.jpg);
        height: 200px;
        background-repeat: no-repeat;
        background-size: cover;
        background-position: center;
    }
    
    .about-name {
        text-align: center;
        font-family: 'Times New Roman', Times, serif;
    }
    
    .about-cont-two {
        margin-left: 20px;
        padding-right: 20px;
        padding-top: 50px;
        padding-bottom: 50px;
        font-family: 'Times New Roman', Times, serif;
    }
    
    .who-cont {
        background-color: black;
        color: white;
        padding-top: 30px;
        padding-bottom: 30px;
        margin-bottom: 40px;
    }
    
    .who-cont-t {
        background-color: whitesmoke;
    }
    
    .who-we {
        font-size: 30px;
        padding-bottom: 20px;
        padding-top: 30px;
    }
    
    .who-ans {
        color: rgb(223, 221, 221);
    }
    
    .teams-name {
        font-family: 'Times New Roman', Times, serif;
        text-align: center;
    }
    
    .abt-card-a {
        width: 250px;
        box-shadow: 0 8px 16px 0 rgba(0,0,0,0.2);
        margin-bottom: 40px;
    }
    
    .team-cont {
        margin-left: 85px;
        margin-top: 20px;
    }
}

.bk-tr {
    background-image: url(../public/trump.jpg);
    height: 600px;
    background-repeat: no-repeat;
    background-size: cover;
    margin-left: 100px;
    margin-right: 100px;
    margin-top: 40px;
    margin-bottom: 40px;
    opacity: 0.9;
}

.trump-cmt {
    color: rgb(255, 0, 0);
    font-family: 'Times New Roman', Times, serif;
    padding-top: 150px;
    padding-left: 300px;
}

.trump-title {
    color: white;
    font-family: 'Times New Roman', Times, serif;
    font-size: 30px;
    font-weight: bold;
    padding-left: 150px;
}

.trump-para {
    color: white;
    font-family: 'Times New Roman', Times, serif;
    padding-left: 90px;
}

.trump-para-two {
    color: white;
    font-family: 'Times New Roman', Times, serif;
    padding-left: 130px;
}

.trump-para-thr {
    color: white;
    font-family: 'Times New Roman', Times, serif;
    padding-left: 280px;
}

.trump-name {
    color: white;
    font-family: 'Times New Roman', Times, serif;
    font-weight: bold;
    padding-left: 261px;
}

@media (max-width: 768px) {
    .bk-tr {
        background-image: url(../public/trump.jpg);
        height: 300px;
        width: 95%;
        background-repeat: no-repeat;
        background-size: cover;
        background-position: center;
        margin-left: 10px;
        margin-right: 100px;
        margin-top: 40px;
        margin-bottom: 40px;
        opacity: 0.9;
    }
    
    .trump-cmt {
        color: rgb(255, 0, 0);
        font-family: 'Times New Roman', Times, serif;
        padding-top: 30px;
        padding-left: 70px;
    }
    
    .trump-title {
        color: white;
        font-family: 'Times New Roman', Times, serif;
        font-size: 15px;
        font-weight: bold;
        padding-left: 10px;
    }
    
    .trump-para {
        color: white;
        font-family: 'Times New Roman', Times, serif;
        padding-left: 0px;
        padding-right: 180px;
        font-size: 12px;
    }
    
    .trump-para-two {
        color: white;
        font-family: 'Times New Roman', Times, serif;
        padding-left: 10px;
        font-size: 12px;
        padding-right: 195px;
    }
    
    .trump-para-thr {
        color: white;
        font-family: 'Times New Roman', Times, serif;
        padding-left: 20px;
        font-size: 12px;
    }
    
    .trump-name {
        color: white;
        font-family: 'Times New Roman', Times, serif;
        font-weight: bold;
        padding-left: 38px;
    }
}

.draw-cont {
    padding-bottom: 80px;
    padding-top: 50px;
}

.draw-img {
    width: 500px;
}

.draw-contone {
    text-align: center;
    padding-left: 120px;
}

.draw-li {
    color: red;
    font-family: 'Times New Roman', Times, serif;
    margin-top: 30px;
}

.draw-gift {
    color: black;
    font-family: 'Times New Roman', Times, serif;
    font-size: 40px;
}

.draw-id {
    color: black;
    font-family: 'Times New Roman', Times, serif;
}

.draw-name {
    color: black;
    font-family: 'Times New Roman', Times, serif;
    font-weight: bold;
}

.card-title {
    font-family: 'Times New Roman', Times, serif;
    font-weight: bold;
    text-align: center;
}

.card-text-po {
    font-family: 'Times New Roman', Times, serif;
    font-size: 17px;
    color: red;
    text-align: center;
}

.card-text {
    font-family: 'Times New Roman', Times, serif;
    text-align: center;
}

.social-team-icons {
    margin-left: 30px;
}

.social-team-icons-i {
    margin-left: 50px;
}

/*blogone */

.main-title-for-blog {
    font-family: 'Times New Roman', Times, serif;
    color: red;
    padding-top: 140px;
    padding-left: 80px;
}

.blog-info-sec {
    padding-left: 80px;
    padding-top: 30px;
    text-align: justify;
}

.blog-title {
    font-family: 'Times New Roman', Times, serif;
}

.blog-para-one {
    font-family: 'Times New Roman', Times, serif;
    font-size: 20px;
}

.humor-tit-one-blog {
    font-family: 'Times New Roman', Times, serif;
    padding-left: 100px;
    color: blue;
}

.humor-video {
    padding-left: 250px;
    width: 1200px;
}

.fourofour {
    margin-top: 200px;
    margin-bottom: 200px;
    text-align: center;
    font-size: 70px;
}

.blog-one-main-img {
    width: 600px;
    height: 400px;
}

/*contact-page */

.contact-form {
    padding-left: 85px;
    padding-top: 85px;
    padding-bottom: 85px;
    border-style: dotted;
    background-color: rgb(218, 218, 218);
}
  
  .name {
    font-size: 20px;
    font-family: Georgia, 'Times New Roman', Times, serif;
    color: rgb(0, 0, 0);
  }
  
  .first-name {
    width: 360px;
    height: 40px;
    font-size: 20px;
    margin-bottom: 5px;
  }
  
  .last-name {
    width: 360px;
    height: 40px;
    font-size: 20px;
    margin-bottom: 20px;
  }
  
  .email-hol {
    width: 360px;
    height: 40px;
    font-size: 20px;
    margin-bottom: 20px;
  }
  
  .phone {
    width: 360px;
    height: 40px;
    font-size: 20px;
    margin-bottom: 20px;
  }
  
  .msg {
    width: 360px;
    height: 140px;
    font-size: 20px;
    margin-bottom: 0px;
    padding-bottom: 100px;
  }
  
  .button-contone {
    margin-top: 40px;
    background-color: rgb(0, 0, 0);
    border-radius: 12px;
    border: 2px rgb(255, 255, 255);
    font-size: 20px;
    color: rgb(255, 255, 255);
    font-weight: bold;
    padding: 14px 40px;
  }
  
  .button-contone:hover {
    background-color: rgb(201, 201, 201);
    color: rgb(0, 0, 0);
  }

  .contact-page {
      padding-left: 200px;
      margin-bottom: 50px;
      margin-top: 50px;
      font-family: 'Times New Roman', Times, serif;
  }

  .contact-us-para {
      font-size: 18px;
  }

  .contact-us {
      padding-top: 200px;
  }

  .Privacy-Policy-page {
      padding-left: 110px;
      padding-right: 110px;
  }

  .pp-pp-pp {
      font-family: 'Times New Roman', Times, serif;
  }

  .four-o-four {
    background-image: url(../public/four.png);
    height: 600px;
    background-repeat: no-repeat;
    background-size: cover;
  }

  @media (max-width: 768px) {
    .four-o-four {
        background-image: url(../public/four.png);
        height: 200px;
        background-repeat: no-repeat;
        background-size: cover;
        background-position: center;
    }
  }